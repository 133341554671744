<template>
    <div class="address">
        <el-button type="primary" @click="_onclick">{{title}}</el-button>
        <el-dialog :title="title" custom-class="y-dialog" :close-on-click-modal="false" :visible.sync="dialogVisible" width="50%" :append-to-body="true">
            <div style="margin-bottom: 10px;display:flex;justify-content: space-between;">
                <el-button @click="_clickNew('新增地址')" v-permission="'Addr:add'" icon="el-icon-plus">新增地址</el-button>

                <div style="display:flex;gap: 10px;">
                    <el-input v-model="initForm.T_Address" clearable style="width: 200px;" placeholder="地址"></el-input>
                    <el-button type="primary" icon="el-icon-search" @click="_searchs()"></el-button>
                </div>
            </div>
            <el-table :data="tableData" border style="width: 100%" size="small">
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column label="公司名称" prop="T_name"></el-table-column>
                <el-table-column label="地址" prop="T_Address"></el-table-column>
                <el-table-column label="手机号" prop="Phone"></el-table-column>
                <el-table-column label="操作" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="small" v-permission="'Addr:update'" @click="_clickNew('编辑地址',scope.row)" >编辑</el-button>
                        <el-button size="small" v-permission="'Addr:del'" @click="_setdelApi(scope.row.Id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="Theorder-pagination">
                <el-pagination
                :hide-on-single-page="true"
                @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                    :current-page="initForm.page" :page-size="initForm.page_z" layout="total, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
            <el-dialog width="500px"  custom-class="y-dialog" :close-on-click-modal="false" :title="innerTitle" :visible.sync="innerVisible" destroy-on-close append-to-body>
                <el-form :model="ruleForms" :rules="addressRules" ref="ruleForms" label-width="80px" class="demo-ruleForm">
                    <el-form-item label="公司名称" prop="T_name">
                        <el-input v-model="ruleForms.T_name" placeholder="公司名称"></el-input>
                    </el-form-item>
                    <el-form-item label="地址" prop="T_Address">
                        <el-input v-model="ruleForms.T_Address" placeholder="地址"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="Phone">
                        <el-input v-model="ruleForms.Phone" maxlength="11" placeholder="手机号"></el-input>
                    </el-form-item>
                </el-form>
                <el-divider></el-divider>
                <div class="_footer">
                    <el-button type="primary" :disabled="disabled" @click="submitForm('ruleForms')">立即提交</el-button>
                    <el-button @click="innerVisible = false">取消</el-button>
                </div>
            </el-dialog>
        </el-dialog>
    </div>
</template>
<script>
import {
    AddressList,
    AddressAdd,
    AddressEdit,
    AddressDel
} from '@/api/instrument/Theorder.js'
import { addressRules } from "./js/rules.js";
import { initForm,ruleForms } from "./js/initParam.js";
    export default {
        data() {
            return {
                tableData:[],
                title:'地址管理',
                dialogVisible: false,
                initForm,//列表请求参数
                total: 0,
                innerVisible:false,
                innerTitle:'',
                ruleForms,//新增、编辑公司参数
                addressRules,
                disabled:false
            }
        }, 
        methods: {
            _onclick() {
                this.dialogVisible = true
                this.initForm = {...initForm}
                this._getAddressList()
            },
            _searchs(){
                this.initForm.page = 1
                this._getAddressList()
            },
            _getAddressList() {
                console.log(this.initForm)
                AddressList(this.initForm).then(res => {
                    let {data:result} = res
                    if (result.Code == 200) {
                        this.total = res.data.Data.Num || 0
                        this.tableData = res.data.Data.Data || []
                    }
                }) 
            },
            /**
             * 提交表单
             * @param formName 
             */
            submitForm(formName) {
                this.$refs[formName].validate(async (valid) => {
                    if (valid) {
                        this.disabled = true
                        if (this.innerTitle == '新增地址') {
                            const {data:result} = await AddressAdd(this.ruleForms)
                            if(result.Code == 200){
                                this.innerVisible = false
                                // setTimeout(() => this.disabled = false, 100);
                                this._getAddressList()
                                this.$message.success(result.Msg)
                            }
                        }else{//编辑地址
                            const {data:result} = await AddressEdit(this.ruleForms)
                            if(result.Code == 200){
                                this.innerVisible = false
                                // setTimeout(() => this.disabled = false, 100);
                                this._getAddressList()
                                this.$message.success(result.Msg)
                            }
                        }

                    } 
                }) 
            },
            /**
             * 新增地址，编辑地址
             * @param title 
             * @param row 
             */
           async _clickNew(title,row=null) {
                console.log(title,row)
                this.innerTitle = title
                this.innerVisible = true
                this.disabled = false
                if (this.$refs.ruleForms) {//为了验证第一次触发表单验证，第二次打开还是触发
                    this.$refs.ruleForms.resetFields();
                }
                if(title == '编辑地址'){
                    Object.keys(this.ruleForms).forEach(key => {
                        this.ruleForms[key] = row[key]
                    })
                    this.ruleForms.T_id = row.Id
                }else{
                    Object.keys(this.ruleForms).forEach(key => {
                        this.ruleForms[key] = ''
                    })
                }
            },
            /**
             * 删除地址
             * @param id
             */
            _setdelApi(id) {
                let _this = this
                this.$confirm('删除操作，是否继续？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    AddressDel({ T_id: id }).then(res => {
                        if (res.data.Code === 200 && res.data.Msg == "ok!") {
                            _this.initForm.page = 1
                            _this._getAddressList()
                            _this.$message.success('删除成功')
                        }
                    })
                }).catch(() => {});
            },
            /**
             * 每页多少条
             */
            handleSizeChange(e) { 
                this.initForm.page_z = e
                this._getAddressList()
            },
            /**
             * 跳转页
             */
            handleCurrentChange(e) {
                this.initForm.page = e
                this._getAddressList()
            },
        }
    }
</script>
<style lang="scss">
.y-dialog {
    border-radius: 15px;
}
.Theorder-pagination{
    margin-top: 20px;
}
</style>